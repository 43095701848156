<template>
  <v-row justify="center">
    <v-col cols="6" class="pt-6">
        <v-btn outlined color="primary" @click="editable || creation ? returnDialog = true : $router.push({name: 'Tasks'})"> 
            <v-icon dark left> mdi-arrow-left </v-icon>Zurück
        </v-btn>
      <div class="pt-6">
        <v-card>
          <v-row class="pl-4">
            <v-col cols="8">
              <v-row>
                <v-col cols="8">
                  <v-select
                    v-model="task.taskType"
                    :items="questionTypes"
                    label="Fragentyp"
                    menu-props="auto"
                    hide-details
                    single-line
                    :disabled="!typeCanBeSwitched"
                  ></v-select>
                  <v-combobox
                    clearable
                    multiple
                    small-chips
                    :items="availableTags"
                    label="Tags"
                    v-model="task.tags"
                    :disabled="!editable"
                    :deletable-chips="true"
                  ></v-combobox>
                    <v-text-field
                    placeholder="Titel"
                    v-model="task.name"
                    :disabled="!editable"
                    :error-messages="nameErrors"
                    @input="$v.task.name.$touch"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="4" v-if="!creation">
                  <v-row justify="end" class="pa-6">
                    <v-icon
                      @click="editable = true"
                      color="#A01441"
                      v-show="!editable"
                      >mdi-lead-pencil</v-icon
                    >
                    <v-icon
                      @click="deleteDialog=true"
                      color="#A01441"
                      v-show="!editable"
                      style="margin-left:4px"
                      >mdi-delete</v-icon
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-textarea
                placeholder="Beschreibung der Frage"
                v-model="task.question"
                :disabled="!editable"
                :error-messages="questionErrors"
                @input="$v.task.question.$touch"
              ></v-textarea>
            </v-col>
            <v-col cols="4" ref="qrcolumn" class="pa-8">
              <v-row align="center" justify="center">
                <qrcode-vue
                  :value="task.qrString"
                  level="Q"
                  :size="qrSize"
                  v-if="task.qrString && task.taskType == 'QR' && !laoding"
                />
              </v-row>
            </v-col>
          </v-row>

          <!-- Answer possibilities !-->
          <v-container v-if="task.taskType === 'MC' || task.taskType === 'SC'" class="pl-4 pt-6">
            <v-row class="pl-3" align="center">
              Antwortmöglichkeiten:
               <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="addToAnswerPossiblities" v-on="on" v-bind="attrs">
                                <v-icon v-if="editable" color="#A01441">mdi-plus</v-icon>
                            </v-btn>
                        </template>
                    <span>Neue Antwort hinzufügen</span>
                </v-tooltip>
            </v-row>

            <v-row
              v-for="(answerPossibility, index) in task.possibleAnswers" 
              :key="`answer-${index}`"
              class="pl-3"
              align="center"
            >
              <v-text-field v-model="task.possibleAnswers[index]" :disabled="!editable" @change="filterPossibleAnswers"/>
              <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="removeFromAnswerPossibilities(index)" v-on="on" v-bind="attrs">
                                <v-icon v-if="editable" color="#A01441">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    <span>Antwort löschen</span>
                </v-tooltip>
            </v-row>

            <v-container>
              <v-row class="pt-4">
                Richtige Antwort(en):
              </v-row>
              <v-row>
                <v-select
                  v-if="task.taskType === 'MC'"
                  multiple
                  v-model="task.expectedAnswers"
                  :items="task.possibleAnswers"
                  :label="'Richtige Antworten wählen'"
                  :disabled="!editable || (task.possibleAnswers.length===0 || !answerCanBeSelected)"
                ></v-select>
                <v-select
                  v-if="task.taskType === 'SC'"
                  v-model="task.expectedAnswer"
                  :items="task.possibleAnswers"
                  :label="'Richtige Antwort wählen'"
                  :disabled="!editable || task.possibleAnswers.length < 1"
                ></v-select>
              </v-row>
              <v-row>
                <p v-if="!answerCanBeSelected" style="color: red">Zwei Mal die selbe Antwort vorhanden!</p>
              </v-row>
            </v-container>
          </v-container>

          <v-row v-if="editable" justify="end" class="pb-4">
            <v-btn
              @click="saveDialog=true"
              color="green"
              outlined
              style="margin-right: 20px; margin-left: 10px"
              :disabled="$v.$invalid"
              >Save</v-btn
            >
          </v-row>
        </v-card>
      </div>
    </v-col>
    <Dialog v-bind:function="'save'" v-bind:dialog="saveDialog" v-bind:subject="'task'" v-on:saveClicked="save()" v-on:abortClicked="saveDialog=false"/>
    <Dialog v-bind:function="'delete'" v-bind:dialog="deleteDialog" v-bind:subject="'task'" v-on:deleteClicked="deleteTask()" v-on:abortClicked="deleteDialog=false"/>
    <Dialog v-bind:function="'return'" v-bind:dialog="returnDialog" v-bind:subject="'change'" v-on:returnClicked="$router.push({name: 'Tasks'})" v-on:abortClicked="returnDialog=false"/>
  </v-row>
</template>

<script>
import axios from "axios";
import QrcodeVue from "qrcode.vue";
import {required, requiredIf} from 'vuelidate/lib/validators'
import Dialog from '../components/Dialog.vue'

const expectedAnswerTest  = (val, vm) => { return (vm.taskType === 'SC' && val !== 'TBD')     || vm.taskType !== 'SC' }
const expectedAnswersTest = (val, vm) => { return (vm.taskType === 'MC' && val.length !== 0 ) || vm.taskType !== 'MC' }

export default {
  name: "AdminTaskDetailView",

  components: { QrcodeVue, Dialog },

  data: () => ({
    availableTags: [],
    laoding: true,
    saveDialog: false,
    deleteDialog: false,
    task: {
      taskType: null,
      name: "",
      question: "",
      expectedAnswer: "TBD",
      expectedAnswers: [],
      possibleAnswers: [],
      points: 1,
      tags: [],
    },
    id: "create",
    editable: false,
    questionTypes: ["QR", "MC", "SC"],
    ready: false,
    numberOfAnswers: 0,
    returnDialog: false
  }),

  validations: {
    task: {
      name: {required},
      question: {required},
      possibleAnswers: {required: requiredIf(function() {
        return this.mcTask
      })},
      expectedAnswer:{
        test: expectedAnswerTest
      },
      expectedAnswers:{
        test: expectedAnswersTest
      }
    }
  },

  computed: {
    editableLabel() {
      return this.editable ? "Stop editing" : "Start editing";
    },
    creation() {
      return this.$route.params.id === "create";
    },
    qrSize() {
      return this.ready ? this.$refs.qrcolumn.clientWidth - 50 : 150;
    },
    typeCanBeSwitched() {
        if (this.task.taskType === "MC" || this.task.taskType === "SC") {
            if (this.task.possibleAnswers.length > 0)
                return false
        }
        return this.creation && !this.task.name && !this.task.question
    },
    answerCanBeSelected() {
        if (this.task.taskType !== "MC" && this.task.taskType !== "SC")
            return false
        if (new Set(this.task.possibleAnswers).size !== this.task.possibleAnswers.length)
            return false
        return true
    },
    nameErrors () {
      const errors = []
      if (!this.$v.task.name.$error) return errors
      !this.$v.task.name.required && errors.push('Name wird benötigt')
      return errors
    },
    questionErrors () {
      const errors = []
      if (!this.$v.task.question.$error) return errors
      !this.$v.task.question.required && errors.push('Fragentext wird benötigt')
      return errors
    },
    mcTask () {
      return this.task.taskType === 'MC' ? true : false
    },
    taskTest () {
      return this.task.taskType
    }
  },

  watch: {
    taskTest: function() {
      if (this.creation && (this.task.taskType === 'QR' || 'MC')) {
        this.editable = true;
      }
    },
  },

  mounted() {
    axios.get(this.$hostname + "tasks/tags/", {
        headers: { Authorization: 'Bearer ' + this.$keycloak.token } }).then(res => {
            this.availableTags = res.data.sort((a, b) => a.localeCompare(b))
        })
    let urlId = this.$route.params.id;
    this.ready = true;
    if (urlId !== "create") {
      axios.get(this.$hostname + "tasks/" + urlId, {
        headers: { Authorization: 'Bearer ' + this.$keycloak.token }}).then((response) => {
        if (response.status === 200) {
          this.task = response.data
          console.log(this.task.kind)
          switch(this.task.kind) {
            case 'MCTask':
              this.task.taskType = "MC";
              this.numberOfAnswers = this.task.possibleAnswers.length;
              break
            case 'SCTask':
              this.task.taskType = "SC"
              break
            default:
              this.task.taskType = "QR";
          }
          this.laoding = false
        }
      })
      .catch((err) => {
        this.loading = false
        this.$snackbar.openSnackbar("red", err)
      })
    }
    
  },

  methods: {
    filterPossibleAnswers: function() {
      this.task.expectedAnswers = this.task.expectedAnswers.filter(answer => this.task.possibleAnswers.includes(answer))
    },

    save: function() {
      switch (this.task.taskType) {
          case "MC":
              this.task.kind = "MCTask"
              this.task.points = this.task.expectedAnswers.length
              break;
          case "SC":
              this.task.kind = "SCTask"
              break;
          case "QR":
              this.task.kind = "QRTask"
              break;
          default:
              break;
      }
      if (this.creation) {
        axios
          .post(this.$hostname + "tasks", this.task, {
            headers: { Authorization: 'Bearer ' + this.$keycloak.token}})
          .then((response) => {
            this.task = response.data;
            if (this.task.kind === "QRTask") {
              this.task.qrString = this.task._id;
              this.update();
            } else {
              this.$router.push({ name: "Tasks" });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.update();
      }
    },

    update: function() {
      this.laoding = true
      axios
        .put(this.$hostname + "tasks", this.task,  {
          headers: {
              Authorization: 'Bearer ' + this.$keycloak.token
              }
          })
        .then(() => {
          this.$router.push({ name: "Tasks" });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    deleteTask: function() {
      axios
        .delete(this.$hostname + "tasks/" + this.task._id, {
        headers: {
         Authorization: 'Bearer ' + this.$keycloak.token
        }
       })
        .then(() => {
          this.$router.push({ name: "Tasks" });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addToAnswerPossiblities: function() {
        this.numberOfAnswers++;
        this.task.possibleAnswers.push("Antwort " + this.numberOfAnswers);
    },
    removeFromAnswerPossibilities (index) {
        let currAnswer = this.task.possibleAnswers[index]
        this.task.possibleAnswers.splice(index, 1)
        if (this.task.expectedAnswer === currAnswer)
            this.task.expectedAnswer = "TBD"
        if (this.task.taskType === "MC")
        this.task.expectedAnswers.filter(answer => answer !==currAnswer)
        this.numberOfAnswers--;
    },
  },
};
</script>
