<template>
    <div>
      <div v-if="!loading">
        <div v-for="template in templates" :key="template._id">
          <v-row>
            <v-col cols="1" :align-self="'center'" v-if="showCreationButton">
            <v-icon @click="creationBtnClicked(template)" color="green">mdi-plus-circle-outline</v-icon>
          </v-col>
          <v-col cols="showCreationButton ? 11 : 12">
            <admin-template-preview :name="template.name" :questionCount="template.questions.length" :id="template._id" @reloadTemplates="getData" :rallyCreation="showCreationButton"/>
          </v-col>
          </v-row>
        </div>
      </div>
      <div v-else>
        <v-row justify="center" class="pt-6">
          <v-progress-circular
            indeterminate
            color="#A01441"
          >
          </v-progress-circular>
        </v-row>
      </div>
    </div>
    
</template>

<script>
import AdminTemplatePreview from './AdminTemplatePreview.vue'
const axios = require('axios')

  export default {
    name: 'AdminTemplateList',
       components: {
              AdminTemplatePreview
        },
    props: ["showCreationButton"],

    data: () => ({
     templates: [],
     loading: true
    }),

    mounted() {
        this.getData()
    },

    methods: {
      creationBtnClicked(template) {
        this.$emit('creationButtonClicked', template)
      },
      getData() {
        axios
          .get(this.$hostname + "templates", { headers: { Authorization: 'Bearer ' + this.$keycloak.token}})
          .then(response => {
            this.loading = false
            this.templates = response.data
          })
          .catch(err => {
            this.loading = false
            this.$snackbar.openSnackbar("red", err)
          })
      }
    }
  }
</script>
