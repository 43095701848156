<template>
    <v-row justify="center">
        <v-container v-if="loading">
            <v-row justify="center" class="pt-4 pb-4">
                <v-progress-circular
                    indeterminate
                    color="#A01441"
                ></v-progress-circular>
            </v-row>
        </v-container>
        <v-container v-if="!loading">
            <v-row justify="center" class="pt-6">
                <v-col cols="8">
                    <div class="pb-3">
                        <v-btn
                            color="#A01441"
                            dark
                            @click="returnToRallies()"
                            outlined
                        >
                            <v-icon dark left> mdi-arrow-left </v-icon>Zurück
                        </v-btn>
                    </div>
                    <v-card>
                        <v-card-title><h3>Rally: {{rally.name}}</h3></v-card-title>
                        <v-card-subtitle style="color: #A01441">Status: {{rally.status === "active" ? "Aktiv" : "Nicht Aktiv"}}</v-card-subtitle>
                        <v-card-text>
                            <p>Start Text: {{rally.startText ? rally.startText : "-"}}</p>
                            <p>End Text: {{rally.endText ? rally.endText : "-"}}</p>
                            <v-divider></v-divider>
                            <div class="pt-6 pb-6">
                                <h3 style="color: #A01441" class="pb-2">Teams:</h3>
                                <v-row>
                                    <v-col cols="4" v-for="team in rally.teams" :key="team.id">
                                        <v-card>
                                            <v-card-title class="centerContent">{{team.name}}</v-card-title>
                                            <v-card-text>
                                                <v-expansion-panels accordion :flat="true">
                                                    <v-expansion-panel>
                                                        <v-expansion-panel-header>Startpunkt: {{getStartingPoint(team.teamTasks)}}</v-expansion-panel-header>
                                                            <v-expansion-panel-content>
                                                                <div v-for="(teamTask, i) in team.teamTasks" :key="teamTask._id">
                                                                    {{i + 1}}: {{rally.tasks.find(fullTask => fullTask._id === teamTask.task).name}}
                                                                </div>
                                                            </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                                
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-divider></v-divider>
                            <div class="pt-6 pb-6">
                                <h3 style="color: #A01441">Aufgaben:</h3>
                                <v-data-table :headers="headers" :items="rally.tasks" :search="search" loading="true" :items-per-page="-1"
                                    :footer-props="{'items-per-page-options':[-1, 1, 5, 10, 25, 50, 100]}"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-row>
</template>

<script>

    import axios from "axios";

    export default {
        name: 'AdminRallyDetail',
        methods:{
            getStartingPoint (teamTasks) {
                if (teamTasks.length > 0) {
                    let spTeamTask = teamTasks[0].task
                    let spTask     = this.rally.tasks.filter((task) => { return task._id === spTeamTask})
                    if (spTask.length > 0)
                        return spTask[0].name
                }
                return "-"
            },
            returnToRallies () {
                this.$router.push({ name: 'Rallies' })
            }

        },
        data() {
            return {
                tab: null,
                loading: true,
                rally: {},
                headers: [
                    {text: 'Name', value: 'name'},
                    {text: 'Frage', value: 'question'},
                    {text: 'Fragen-Art', value: 'kind'},
                    {text: 'Punkte', value: 'points'},
                ],
                search: '',
            }
        },
        mounted() {
            let urlId = this.$route.params.id;
            if (urlId !== "create") {
            axios.get(this.$hostname + "rallies/detail/" + urlId, {
                headers: {
                    Authorization: 'Bearer ' + this.$keycloak.token
                    }
                })
                .then((response) => {
                    if (response.data)
                        this.rally = response.data
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.loading = false
                    this.$snackbar.openSnackbar("red", err)
                })
            }
        },
    }
</script>

<style scoped>
  .centerContent {
    display: flex;
    justify-content: center;
  }
</style>