<template>
    <div class="mb-7">
      <v-card @click="openDetail()">
        <v-row>
          <v-col cols="8">
            <v-card-title>
              {{ralley.name}}
            </v-card-title>
          </v-col>
          <v-col cols="3" class="text-right" :align-self="'center'">
            <v-tooltip v-if="!active" top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="$emit('analysisClicked', ralley)" v-on:click.stop>
                        <v-icon color="primary" v-on="on" v-bind="attrs">mdi-chart-box-outline </v-icon>
                    </v-btn>
                </template>
              <span>Auswertung</span>
            </v-tooltip>

            <v-tooltip v-if="!active" top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="startStopDialog=true" v-on:click.stop>
                        <v-icon color="green" v-on="on" v-bind="attrs">mdi-play-circle-outline</v-icon>
                    </v-btn>
                </template>
              <span>Rally starten</span>
            </v-tooltip>
            
            <v-tooltip v-if="active" top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="startStopDialog=true" v-on:click.stop>
                        <v-icon color="primary" v-on="on" v-bind="attrs">mdi-stop-circle-outline</v-icon>
                    </v-btn>
                </template>
              <span>Rally stoppen</span>
            </v-tooltip>
            
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="downloadPDF" v-on:click.stop>
                        <v-icon color="primary" v-on="on" v-bind="attrs">mdi-file-pdf-box</v-icon>
                    </v-btn>
                </template>
              <span>PDF herunterladen</span>
            </v-tooltip>
            
            <v-tooltip v-if="!active" top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="deleteDialog=true" v-on:click.stop>
                        <v-icon v-if="!active" color="red" v-on="on" v-bind="attrs">mdi-delete</v-icon>
                    </v-btn>
                </template>
              <span>Rally löschen</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <admin-rally-analysis @close='dialog=false' :dialog="dialog" :rally="ralley"></admin-rally-analysis>
      </v-card>    
      <Dialog v-bind:function="dialogFunction" v-bind:dialog="startStopDialog" v-bind:subject="'rally'" v-on:startClicked="activateRally()" v-on:stopClicked="deactivateRally()" v-on:abortClicked="startStopDialog=false"/>
      <Dialog v-bind:function="'delete'" v-bind:dialog="deleteDialog" v-bind:subject="'rally'" v-on:deleteClicked="deleteRally()" v-on:abortClicked="deleteDialog=false"/>
    </div>
</template>

<script>//$emit('analysis', ralley)
import axios from 'axios'
import AdminRallyAnalysis from './AdminRallyAnalysis.vue'
import Dialog from './Dialog.vue'

  export default {
      props: ["ralley"],
        name: 'RalleyList',
       components: {
              AdminRallyAnalysis,
              Dialog
        },

    data: () => ({
      dialog: false,
      startStopDialog: false,
      deleteDialog: false
    }),

    computed: {
      active() {
        return this.ralley.status === "active"
      },
      dialogFunction() {
        return this.active ? 'stop' : 'start'
      }
    },

    mounted() {

    },
    methods: {
      activateRally: function () {
        axios
          .put(this.$hostname + 'rallies/' + this.ralley._id, {status: "active"}, {headers: {
         Authorization: 'Bearer ' + this.$keycloak.token
        }})
          .then(() => {
            this.$emit("modified")
          })
          .catch(error => {this.handleError(error)})
      },

      deactivateRally: function () {
        axios
          .put(this.$hostname + 'rallies/' + this.ralley._id, {status: "dormant"}, {headers: {
         Authorization: 'Bearer ' + this.$keycloak.token
        }})
          .then(() => {
            this.$emit("modified")
          })
          .catch(error => {this.handleError(error)})
      },
        
      deleteRally: function () {
        axios
          .delete(this.$hostname + 'rallies/' + this.ralley._id, {
        headers: {
         Authorization: 'Bearer ' + this.$keycloak.token
        }
       })
          .then(() => {
            this.$emit("modified")
          })
          .catch(error => {this.handleError(error)})
      },

      downloadPDF: function () {
        axios
          .get(this.$hostname + 'rallies/pdf/' + this.ralley._id, {headers: {
         Authorization: 'Bearer ' + this.$keycloak.token
        },responseType: 'blob'})
          .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${this.ralley.name}.pdf`);
              document.body.appendChild(link);
              link.click();
          })
          .catch(error => {this.handleError(error)})
      },
      
      openDetail() {
        this.$router.push({name: 'AdminRallyDetail', params: {id: this.ralley._id}})
      },

      handleError (err) {
          this.loading = false
          this.$snackbar.openSnackbar("red", err)
      }
    }
  }
</script>