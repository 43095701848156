<template>
    <div>
        <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
            <v-toolbar
            dark
            color="primary"
            >
            <v-btn
                icon
                dark
                @click="$emit('close')"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{rally.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-col cols="4" offset="4">
                <div v-if="!loading">
                    <v-list
                        three-line
                        subheader
                    >
                        <v-list-item v-for="(team, index) in teamsObj" :key="team._id">
                            <v-list-item-action>
                                <div class="pa-7 rounded-circle d-inline-block" :class="medalColor(index + 1)">{{index + 1}}</div>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{team.name}}</v-list-item-title>
                                <v-list-item-subtitle>Team {{team.name}} hat {{team.points}} aus {{maxPoints}} Punkten erreicht!</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
                <div v-else>
                    <v-row justify="center" class="pt-6">
                    <v-progress-circular
                        indeterminate
                        color="#A01441"
                    >
                    </v-progress-circular>
                    </v-row>
                </div>
            </v-col>
            
            </v-card>
        </v-dialog>
    </div>
    
</template>

<script>
import axios from 'axios';
  export default {
    props: ['rally', 'dialog'],

    data () {
      return {
        maxPoints: 0,
        notifications: false,
        sound: true,
        widgets: false,
        teams: [],
        loading: true,
        teamsObj: {}
      }
    },

    watch: {
        rally: function () {
            axios.get(this.$hostname + `rallies/result/${this.rally._id}/`, {
              headers: {
              Authorization: 'Bearer ' + this.$keycloak.token
              }
            }).then(res => {
                this.maxPoints = res.data.maxPoints
                this.teamsObj = res.data.teamArr
                this.loading = false
            }).catch(err => {
                console.error(err)
                this.$snackbar.openSnackbar("red", err)
            })
        }
    },

    methods: {
        addTwo: function (prev, cur) {
            prev + cur.points
        },
        medalColor: function (place) {
            if (place === 1) {
                return 'yellow'
            } else if (place === 2) {
                return 'grey'
            } else if (place === 3) {
                return 'brown lighten-1'
            } else {
                return 'white'
            }
        }
    },

    mounted() {
        
    },
  }
</script>