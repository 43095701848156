<template>
    <div>
      <div v-if="!loading">
        <v-row>
          <v-col cols="6">
            <v-row justify="center" class="mb-3">
              <h1>Laufende Rallies</h1>
            </v-row>
            <div v-for="ralley in activeRalleys" :key="ralley._id">
                <ralley-preview @analysisClicked="$emit('test')" @modified="update" @analysis="payload => $emit('analysis', payload)" :ralley="ralley"></ralley-preview>
            </div>
          </v-col>
          <v-col cols="6">
            <v-row justify="center" class="mb-3">
              <h1>Inaktive Rallies</h1>
            </v-row>
            <div v-for="ralley in dormantRalleys" :key="ralley._id" @click="openDetail(ralley._id)">
                <ralley-preview @analysisClicked="(payload) => $emit('test', payload)" @modified="update" :ralley="ralley"></ralley-preview>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row justify="center" class="pt-6">
          <v-progress-circular
            indeterminate
            color="#A01441"
          >
          </v-progress-circular>
        </v-row>
      </div>
        
    </div>
    
</template>

<script>
import RalleyPreview from './RalleyPreview.vue'
const axios = require('axios')

  export default {
    name: 'RalleyList',
       components: {
              RalleyPreview
        },

    data: () => ({
     ralleys: [],
     dialog: true,
     loading: true
    }),

    computed: {
      activeRalleys() {
        return this.ralleys.filter(ralley => ralley.status === "active")
      },
      dormantRalleys() {
        return this.ralleys.filter(ralley => ralley.status === "dormant")
      }
    },

    mounted() {
      this.update()
    },
    
    methods: {
      update: function() {
        axios
          .get(this.$hostname + "rallies", {
            headers: {
            Authorization: 'Bearer ' + this.$keycloak.token
            }
          })
          .then(response => {
            this.loading = false
            if(response.status === 200){
              this.ralleys = response.data
            }
          })
          .catch((err) => {
              this.loading = false
              this.$snackbar.openSnackbar("red", err)
          })
      },
      openDetail(id) {
        this.$router.push({name: 'AdminRallyDetail', params: {id: id}})
      }
    }
  }
</script>
