import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Admin from '../views/Admin.vue'
import Rally from '../views/Rally.vue'
import AdminTask from '../views/AdminTask'
import AdminTemplate from '../views/AdminTemplate'
import AdminTaskDetail from '../views/AdminTaskDetail'
import AdminTemplateDetail from '../views/AdminTemplateDetail'
import AdminRalleyCreation from '../views/AdminRalleyCreation'
import AdminRallyDetail from '../views/AdminRallyDetail'
import PageNotFound from '../views/PageNotFound'
import Unauthorized from '../views/Unauthorized'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin',
    name: 'Rallies',
    component: Admin,
    meta: {
      secured: true
    }
  },
  {
  path: '/rallies/:id',
  name: 'Rally',
  component: Rally
  },
  {
    path: '/admin/tasks',
    name: 'Tasks',
    component: AdminTask,
    meta: {
      secured: true
    }
  },
  {
    path: '/admin/templates',
    name: 'Templates',
    component: AdminTemplate,
    meta: {
      secured: true
    }
  },
  {
    path: '/admin/tasks/:id',
    name: 'AdminTaskDetail',
    component: AdminTaskDetail,
    meta: {
      secured: true
    }
  },
  {
    path: '/admin/template/:id',
    name: 'AdminTemplateDetail',
    component: AdminTemplateDetail,
    meta: {
      secured: true
    }
  },
  {
    path: '/admin/rally/new',
    name: 'AdminRallyeCreate',
    component: AdminRalleyCreation,
    meta: {
      secured: true
    }
  },
  {
    path: '/admin/rally/:id',
    name: 'AdminRallyDetail',
    component: AdminRallyDetail,
    meta: {
      secured: true
    }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
  },
  { path: "*",
    name: "NotFound",
    component: PageNotFound }
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.secured) {
    // Get the actual url of the app, it's needed for Keycloak
    const basePath = window.location.toString()
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri: basePath})
    } else {//if (Vue.$keycloak.hasResourceRole()) {
      // The user was authenticated, and has the app role
      if(!Vue.$keycloak.idTokenParsed.groups.includes('/Projects/msg.P.XT.Starttag/msg.P.XT.Starttag.AppAdmin')) {
        router.push({name: 'Unauthorized'})
      } else {
        Vue.$keycloak.updateToken(70)
          .then(() => {
            next()
          })
          .catch(err => {
            console.error(err)
          })
      }
    }
  } else {
    // This page did not require authentication
    next()
  }
})

export default router
