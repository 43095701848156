<template>
    <v-container fill-height>
        <v-container v-if="!scannerOpen" style="align-self: start">
            <v-row style="justify-content: space-between; align-items: center">
                <v-col cols="6" class="pa-0">
                    <h2 class="team">Team: {{team.name}}</h2>
                </v-col>
                <v-col cols="6" class="pa-0" align="end">
                    <v-btn color="#6F6F6F" outlined @click="onNext = true">Nächste Frage</v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="!scannerOpen" class="pa-0 pb-14" style="align-self: flex-start">
            <v-card elevation="8">
                <v-card-title>{{teamTask.task.name}}</v-card-title>
                <v-card-text>
                    {{teamTask.task.question}}
                    <div  v-if="teamTask.task.kind === 'MCTask'">
                        <v-checkbox
                            v-for="checkbox of currentCheckboxes"
                            v-model="checkbox.selected"
                            :key="checkbox.answer"
                            :label="checkbox.answer"
                            dense
                            hide-details=true
                        ></v-checkbox>
                    </div>
                    <div v-if="teamTask.task.kind === 'SCTask'">
                        <v-radio-group v-model="scAnswer">
                            <v-radio
                            v-for="answer in teamTask.task.possibleAnswers"
                            :key="answer"
                            :label="answer"
                            :value="answer"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                    <div v-if="teamTask.task.kind === 'QRTask'">

                    </div>
                </v-card-text>
            </v-card>
        </v-container>

        <v-btn v-if="!scannerOpen" class="controls" absolute bottom right color="primary" @click="answer" :disabled="currentAnswerButtonDisabled">{{currentAnswerButtonText}}</v-btn> 

        <v-container fill-height v-if="scannerOpen">
                        <QRCodeScanner
                            v-on:onDecode="onDecode"
                            v-on:toggleScanner="toggleScanner"
                            v-on:openSnackbar="(a, b) => $emit('openSnackbar', a, b)"
                        ></QRCodeScanner>
        </v-container>

        <v-dialog v-model="onNext">
            <template>
                <v-card>
                    <v-card-title>Bist du dir sicher?</v-card-title>
                    <v-card-text>
                        <h3>Du kannst nicht mehr zu dieser Frage zurück!</h3>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn color="#A01441" text @click="onNext = false">Nein</v-btn>
                        <v-btn color="#A01441" text @click="onNext = false; $emit('skipQuestion')">Ja</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </v-container>
</template>

<script>
import QRCodeScanner from "../components/QrCodeScanner.vue";
export default {
    name: "UserTask",
    props:  [ "teamTask", "team" ],
        components: { QRCodeScanner },
    data: () => ({
        currentCheckboxes: [],
        scAnswer: "",
        scannerOpen: false,
        onNext: false,
        answerButtonText: {
            mcOrSc : "Antwort abschicken",
            qr:      "Kamera öffnen"
        }
    }),
    mounted () {
        this.initMC()
    },
    computed : {
        currentAnswerButtonText () {
            if (this.teamTask.task.kind === "QRTask")
                return this.answerButtonText.qr
            else 
                return this.answerButtonText.mcOrSc
        },
        currentAnswerButtonDisabled () {
            let kind = this.teamTask.task.kind
            if (kind === "SCTask" && this.scAnswer !== "")
                return false
            if (kind === "MCTask" && this.mcAnswered())
                return false
            if (kind === "QRTask")
                return false
            return true
        },
    },
    watch: { 
        teamTask: function () {
            this.initMC()
        }
    },
    methods: {
        // User pressed the answer button
        answer () {
            if (this.teamTask.task.kind === "QRTask") {
                this.scannerOpen = true
            } 
            else if (this.teamTask.task.kind === "MCTask") {
                let answers = []
                for (const checkbox of this.currentCheckboxes) {
                    if (checkbox.selected) {
                        answers.push(checkbox.answer)
                    }
                }
                this.$emit("sendAnswer", answers)
            }
            else if (this.teamTask.task.kind === "SCTask")
                this.$emit("sendAnswer", this.scAnswer)
        },

        // Checks if user checked a checkbox
        mcAnswered () {
            if (!this.currentCheckboxes)
                return false
            else {
                let bolArr = this.currentCheckboxes?.map(c => c.selected)
                return bolArr.includes(true)
            }
        },

        // QR scanner methods
        onDecode (decodedString) {
            this.toggleScanner()
            this.$emit("sendAnswer", decodedString)
        },
        toggleScanner () {
            this.scannerOpen = !this.scannerOpen;
        },

        // Set data layout for mc task
        initMC () {
            if (this.teamTask?.task?.kind === "MCTask") {
                let checkBoxes = []
                if (this.teamTask?.task?.possibleAnswers) {
                    for (const answer of this.teamTask.task.possibleAnswers)
                        checkBoxes.push({answer: answer, selected: false})
                    this.currentCheckboxes = checkBoxes
                }
            }
        }
    }
}
</script>

<style>

.centered-row {
    justify-content: start;
}
.end-row {
    justify-content: end;
}

.team {
    color: #6F6F6F;
    padding: 8px
}

.controls {
    position: absolute;
    bottom: 8px;
    right: 8px;
}
</style>