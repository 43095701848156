<template>
    <start-rally />
</template>

<script>
import StartRally from "../components/StartRally.vue";

export default {
    name: "Home",

    components: {
        StartRally,
    },
};
</script>
