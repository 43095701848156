<template>
  <div>
    <v-row>
      <v-col cols="1" offset="3">
        <v-btn color="#A01441" class="mt-2" dark @click="$router.push({name: 'Rallies'})" outlined>
          <v-icon dark left> mdi-arrow-left </v-icon>Zurück
        </v-btn>
      </v-col>
      <v-col cols="5" style="display: flex; justify-content: center;">
        <h1>Wählen Sie bitte ein Template/Vorlage aus</h1>
      </v-col>
    </v-row>

    <v-row justify="center">
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          hide-overlay
          fullscreen
        >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Neue Rally erstellen</v-toolbar-title>
            <v-spacer/>
            <v-btn
              dark
              text
              @click="dialog = false"              
            >
              Abbrechen
            </v-btn>
            <v-btn
              dark
              text
              @click="createRally"
              :disabled="$v.$invalid"
            >
              Neue Rally anlegen
            </v-btn>
          </v-toolbar>
          <v-row class="justify-center">
            <v-card-title class="text-h5">Rally Details</v-card-title>
          </v-row>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-text class="justify-center">
                  Erzeugen aus Template: {{targetTemplate.name}}
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name der Rally"
                  required
                  v-model="rallyName"
                  @input="$v.rallyName.$touch"
                  :error-messages="nameErrors"
                ></v-text-field>
                <v-textarea
                  label="Start Text"
                  required
                  v-model="rallyStartText"
                  @input="$v.rallyStartText.$touch"
                ></v-textarea>
                <v-textarea
                  label="End Text"
                  required
                  v-model="rallyEndText"
                  @input="$v.rallyEndText.$touch"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <label>Teams</label>
                <multiselect v-model="teams" tag-placeholder="Neues Team hinzufügen"
                placeholder="Teamname" label="name" track-by="name" 
                :options="options" :multiple="true" :taggable="true" @tag="addTag" @remove="removeTag" @input="$v.teams.$touch"
                :error-messages="teamErrors"
                tag-position="bottom"> 
                </multiselect>
                <span class="parsley-error" style="color:red" v-show="$v.teams.$error">{{teamErrors[0]}}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" v-for="teamTaskCol in teamTasks" :key="teamTaskCol.teamName">
                <v-row justify="center" class="mb-3">
                  <h2 style="color:#A01441">Team: {{teamTaskCol.teamName}}</h2>
                </v-row>
                <draggable v-model="teamTaskCol.teamTasks" :group="teamTaskCol.teamName" @start="drag=true" @end="drag=false">
                  <v-card elevation="2" v-for="task in teamTaskCol.teamTasks" :key="task._id" class="ma-3" style="cursor: grab;">
                    <v-card-title>
                      {{task.name}}
                    </v-card-title>
                    <v-card-text>
                      Frage: {{task.question}}
                    </v-card-text>
                    <v-card-actions class="pt-0">
                      <v-btn text @click="selectNewStartingPoint(teamTaskCol.teamName, task)">
                        Als Startpunkt wählen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </draggable>
              </v-col>
            </v-row>
          </v-container>
          </v-card-text>
        </v-card>
    </v-dialog>
  </v-row>
  <v-row>
    <v-col cols="6" offset="3">
      <admin-template-list @creationButtonClicked="openCreationDialog" v-bind:showCreationButton="true" v-bind:rallyCreation="true"></admin-template-list>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import AdminTemplateList from '../components/AdminTemplateList.vue'
import Multiselect from 'vue-multiselect'
import {required, minLength} from 'vuelidate/lib/validators'
import draggable from 'vuedraggable'
const axios = require('axios')

  export default {
    name: 'AdminTemplateView',

    components: {
        AdminTemplateList,
        Multiselect,
        draggable

    },
    data: () => ({
      dialog: false,
      teams: [],
      options: [
        {name: 'Team 1'}, {name: 'Team 2'}, {name: 'Blau'}, {name: 'Rot'}
      ],
      targetTemplate: {name: ''},
      rallyName: '',
      rallyStartText: '',
      rallyEndText: '',
      teamTasks: [],
    }),

    validations: {
        rallyName: {required},
        // Vuelidate evaluates empty array to more Than minLength 1, so required is added
        teams: {
          minLength: minLength(2), required
        },
        rallyStartText: {},
        rallyEndText: {}
    },

    computed: {
      nameErrors () {
        const errors = []
        if (!this.$v.rallyName.$error) return errors
        !this.$v.rallyName.required && errors.push('Name wird benötigt')
        return errors
      },
      teamErrors () {
        const errors = []
        if (!this.$v.teams.$error) return errors
        !this.$v.rallyName.minLength && errors.push('Bitte mindestens 2 Teams auswählen')
        return errors
      },
    },

    watch: {
      teams: function(teams) {
        // Add new object if none exists for that team name
        for (let team of teams) {
          if(!this.teamTasks.some(col => col.teamName === team.name)) {
            this.teamTasks.push({teamName: team.name, teamTasks: this.targetTemplate.questions})
          }
        }

        // Remove every object that has no corresponding team name selected
        let teamNames = teams.map(team => team.name)
        this.teamTasks = this.teamTasks.filter(teamTaskCollection => teamNames.includes(teamTaskCollection.teamName))

        let startStep = Math.max(Math.floor(this.targetTemplate.questions.length / teams.length), 1)
        this.teamTasks.forEach((teamCol,i) => {
          if(i > 0) {
            this.selectNewStartingPoint(teamCol.teamName, this.targetTemplate.questions[0])
          }
          this.selectNewStartingPoint(teamCol.teamName, null , (0 + startStep * i))
        })
      }
    },
    

    methods: {
        selectNewStartingPoint(teamName, newStartTask, startIndex) {
          let teamIndex = this.teamTasks.indexOf(this.teamTasks.find(col => col.teamName === teamName))
          let taskCollection = this.teamTasks[teamIndex].teamTasks
          startIndex = startIndex ?? this.teamTasks[teamIndex].teamTasks.indexOf(newStartTask)
          let newTaskCollection = taskCollection.slice(startIndex).concat(taskCollection.slice(0, startIndex))
          this.teamTasks[teamIndex].teamTasks = newTaskCollection
        },
        createNewRallye(){
            this.$router.push({name: 'Rallies'})
        },

        addTag (newTag) {
          const tag = {
            name: newTag
          }
        //this.options.pop(tag)
        this.teams.push(tag)
        },
        removeTag () {

        },

        openCreationDialog(targetTemplate){
          this.targetTemplate = targetTemplate
          this.rallyStartText = this.targetTemplate.startText
          this.rallyEndText   = this.targetTemplate.endText
          this.dialog         = true
        },

        handleError (err) {
            this.loading = false
            this.$snackbar.openSnackbar("red", err)
        },

        createRally() {
          axios
            .get(this.$hostname + 'templates/' + this.targetTemplate._id, {
              headers: {
              Authorization: 'Bearer ' + this.$keycloak.token
              }
            })
            .then(selectedTemplateRes => {
              let selectedTemplate = selectedTemplateRes.data
              let selectedTemplateQuestions = []
              selectedTemplate.questions.forEach(id => selectedTemplateQuestions.push({status: 'Open', task: id}))

              let promises = []
              this.teams.forEach( () => {
                promises.push(
                  axios
                  .post(this.$hostname + 'teamTasks', {tasks: selectedTemplateQuestions}, {headers: {
                    Authorization: 'Bearer ' + this.$keycloak.token
                  }})
                )}
              )

              Promise.all(promises)
                .then(teamTasksBatches => {
                  let teamObjects = []
                  teamTasksBatches.forEach((teamTaskBatch, i) => {
                    teamObjects.push({name: this.teams[i].name, teamTasks: teamTaskBatch.data.sort((a,b) => {
                      let orderReference = this.teamTasks.find(col => col.teamName === this.teams[i].name).teamTasks
                      return orderReference.indexOf(orderReference.find(task => task._id === a.task)) - orderReference.indexOf(orderReference.find(task => task._id === b.task))
                    })})
                  })

                  axios
                  .post(this.$hostname + 'teams', {teams: teamObjects}, {headers: {
                      Authorization: 'Bearer ' + this.$keycloak.token
                    }})
                    .then(response => {
                      let teamIds = []
                      response.data.forEach(team => {teamIds.push(team._id)})

                        axios
                        .post(this.$hostname + 'rallies', {
                          name: this.rallyName, tasks: selectedTemplate.questions, status: "dormant",
                          teams: teamIds, startText: this.rallyStartText, endText: this.rallyEndText
                        }, {headers: {Authorization: 'Bearer ' + this.$keycloak.token}})
                          .then(() => {
                            this.$router.push({name: 'Rallies'})
                          })
                          .catch(error => {this.handleError(error)})
                    })
                    .catch(error => {this.handleError(error)})

                }).catch(error => {this.handleError(error)})

              })
              .catch(error => {this.handleError(error)})
        }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>