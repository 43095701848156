var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-7"},[_c('v-card',{on:{"click":function($event){return _vm.openDetail()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.ralley.name)+" ")])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3","align-self":'center'}},[(!_vm.active)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":[function($event){return _vm.$emit('analysisClicked', _vm.ralley)},function($event){$event.stopPropagation();}]}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-chart-box-outline ")])],1)]}}],null,false,3986961819)},[_c('span',[_vm._v("Auswertung")])]):_vm._e(),(!_vm.active)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":[function($event){_vm.startStopDialog=true},function($event){$event.stopPropagation();}]}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green"}},'v-icon',attrs,false),on),[_vm._v("mdi-play-circle-outline")])],1)]}}],null,false,1240352860)},[_c('span',[_vm._v("Rally starten")])]):_vm._e(),(_vm.active)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":[function($event){_vm.startStopDialog=true},function($event){$event.stopPropagation();}]}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-stop-circle-outline")])],1)]}}],null,false,4227702423)},[_c('span',[_vm._v("Rally stoppen")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":[_vm.downloadPDF,function($event){$event.stopPropagation();}]}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-file-pdf-box")])],1)]}}])},[_c('span',[_vm._v("PDF herunterladen")])]),(!_vm.active)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":[function($event){_vm.deleteDialog=true},function($event){$event.stopPropagation();}]}},[(!_vm.active)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")]):_vm._e()],1)]}}],null,false,649578696)},[_c('span',[_vm._v("Rally löschen")])]):_vm._e()],1)],1),_c('admin-rally-analysis',{attrs:{"dialog":_vm.dialog,"rally":_vm.ralley},on:{"close":function($event){_vm.dialog=false}}})],1),_c('Dialog',{attrs:{"function":_vm.dialogFunction,"dialog":_vm.startStopDialog,"subject":'rally'},on:{"startClicked":function($event){return _vm.activateRally()},"stopClicked":function($event){return _vm.deactivateRally()},"abortClicked":function($event){_vm.startStopDialog=false}}}),_c('Dialog',{attrs:{"function":'delete',"dialog":_vm.deleteDialog,"subject":'rally'},on:{"deleteClicked":function($event){return _vm.deleteRally()},"abortClicked":function($event){_vm.deleteDialog=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }