import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify/lib'
import Vuelidate from 'vuelidate'
import authentication from './plugins/keycloak'
import VueConfetti from 'vue-confetti'

Vue.config.productionTip = false
Vue.prototype.$hostname = process.env.NODE_ENV === 'production' ? '/backend/' : 'http://localhost:3000/backend/'

Vue.use(Vuetify)
Vue.use(Vuelidate)
Vue.use(VueConfetti)

Vue.use(authentication)
Vue.prototype.$snackbar = {open: false, color: "red"}

Vue.prototype.$snackbar.openSnackbar = function (color, text) {
    this.open  = true
    this.color = color
    this.text  = text
}

Vue.$keycloak
  .init({checkLoginIframe: false })
  .then((auth) => {
    //console.log(Vue.$keycloak.token)
    if(!auth) {
      //this.AccessToken = ""
    } else {
      //this.AccessToken = Vue.$keycloak.token
    }

    new Vue({
    router,
    vuetify : new Vuetify({
      theme: {
        themes: {
          light: {
            primary: '#A01441'
          }
        }
      }
    }),
    render: h => h(App)
  }).$mount('#app')
})