<template>
    <div :absolute="true" style="width: 100%; height: 100%">
        <v-overlay :style="{visibility: initialized ? 'visible' : 'hidden'}">
            <div style="height: 100%">
                <qrcode-stream @decode="onDecode" @init="onInit"> </qrcode-stream>
                
                <v-container>
                    <v-row>
                        <v-col class="text-right">
                            <v-btn
                                color="primary"
                                @click="closeScanner"
                                dark
                            >
                                <v-icon dark left> mdi-arrow-left </v-icon
                                >Zurück</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-overlay>
        <v-container fill-height v-if="!initialized" style="align-content:center">
                    <v-row justify="center" class="pt-6">
                        <v-progress-circular
                            indeterminate
                            color="#A01441"
                        ></v-progress-circular>
                    </v-row>

                    <v-row justify="center" class="pt-6">
                        Kamera startet... <br> Ggf. müssen Berechtigungen erteilt werden!
                    </v-row>
        </v-container>
    </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
    name: "StartRally",
    components: { QrcodeStream },
    data: () => ({
        camera: "rear",
        initialized: false,
        snackbar: {
            open: false,
            text: "Nope",
            color: "red"
        }
    }),

    methods: {
        onDecode(decodedString) {
            this.$emit("onDecode", decodedString);
        },
        closeScanner() {
            this.$emit("toggleScanner");
        },
        onInit(promise) {
            promise
            .then(() => { this.initialized = true})
            .catch((err) => {
                let errorText = "Etwas ist schiefgelaufen."
                if (err.name === "NotReadableError")
                    errorText = "Die Kamera kann nicht geöffnet werden."
                if (err.name === "NotAllowedError")
                    errorText = "Es fehlen die Berechtigungen zum Öffnen der Kamera."
                this.$snackbar.openSnackbar("red", errorText)
                this.$emit("toggleScanner");
            })
        }
    },
};
</script>

<style></style>
