<template>
  <v-row justify="center" class="ma-5">
    <v-tabs v-model="tab" color="primary" centered>
      <v-tab v-on:click="navigate('Rallies')" >Rallies</v-tab>
      <v-tab v-on:click="navigate('Templates')" >Templates</v-tab>
      <v-tab v-on:click="navigate('Tasks')">Tasks</v-tab>
    </v-tabs>
  </v-row>
</template>

<script>

  export default {
    props: ["current"],
    name: 'AdminNavBar',

    components: {
        
    },

    methods:{
      navigate(targetRoute) {
        if(this.$route.name !== targetRoute) {
          this.$router.push({name: targetRoute})
        }
      }
    },

    data() {
      return {
        tab: null
      }
    },

    mounted() {
      switch (this.$route.name) {
          case "Rallies":
            this.tab = 0
            return 0

          case "Templates":
            this.tab = 1
            return 1

          default:
            this.tab = 2
            return 2
        }
    },
  }
</script>