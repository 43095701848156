<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-col>
                <h1>403 - Das dürfen aber nur Admins...</h1>
                <h2>
                    <a href="/">Take me home</a>
                </h2>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {};
</script>

<style></style>
