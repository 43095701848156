<template>
    <div>
        <v-card class="my-3">
            <v-row>
                <v-col md="10">
                    <v-card-title>{{name}}</v-card-title>
                    <v-card-text>Dieses Template enthält {{questionCount}} Fragen</v-card-text>
                </v-col>
                <v-col md="2" align="right">  
                    <v-tooltip top v-if="!rallyCreation">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="dialog = true">
                                <v-icon align="center" v-on="on" v-bind="attrs">mdi-content-duplicate</v-icon>
                            </v-btn>
                        </template>
                    <span>Dieses Template duplizieren</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="$router.push({name: 'AdminTemplateDetail', params: {id: id}})">
                                <v-icon align="center" v-on="on" v-bind="attrs">mdi-chevron-double-right</v-icon>
                            </v-btn>
                        </template>
                    <span>Detailansicht</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card>
        <!-- Duplication dialog !-->
        <v-dialog v-model="dialog" max-width="390">
            <v-card>
                <v-card-title>Template duplizieren</v-card-title>
                <v-card-text>
                    Name der Duplikation:
                    <v-text-field autofocus label="Name" v-model="duplicationName"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">Zurück</v-btn>
                    <v-btn color="#A01441" text @click="duplicate()">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
  export default {
    props: ["name", "questionCount", "id", "rallyCreation"],
    name: 'AdminTaskPreview',
    data: () => ({
        dialog: false,
        duplicationName: ""
    }),
    methods: {
        duplicate() {
            this.dialog = false
            axios.post(this.$hostname + 'templates/duplicate', {id: this.id, name: this.duplicationName}, 
                {headers: {Authorization: 'Bearer ' + this.$keycloak.token}}
            ).then(() => {
                this.duplicationName = ""
                this.$emit("reloadTemplates")
            }).catch((err) => {
                this.$snackbar.openSnackbar("red", err)
            })
        }
    }
  }
</script>