<template>
    <div style="height: 100%">
        <team-selection
            v-if="!currentTeam && teams != null && !loading && !finished"
            :teams="teams"
            @teamSelected="continueToTasks"
            @openSnackbar="openSnackbar"
        />
        <user-task 
            v-if="currentTeam && currentTask && !finished"
            v-bind:teamTask="currentTask"
            v-bind:team="currentTeam"
            @openSnackbar="openSnackbar"
            @sendAnswer="sendAnswer"
            @skipQuestion="skipQuestion"
        />

        <v-snackbar v-model="snackbar.open" :color="snackbarColor" style="padding-bottom: 64px">
            {{ snackbar.text }}
        </v-snackbar>

        <v-container v-if="loading">
            <v-row justify="center" class="pt-6">
                <v-progress-circular
                    indeterminate
                    color="#A01441"
                ></v-progress-circular>
            </v-row>
        </v-container>

        <!-- Start Text Dialog !-->
        <v-dialog v-model="startDialog">
            <template>
                <v-card>
                    <v-card-title>Willkommen!</v-card-title>
                    <v-card-text><h3 style="white-space: pre-wrap;">{{startText}}</h3></v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn color="#A01441" text @click="startDialog = false">Schließen</v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <!-- End of rally !-->
        <v-container v-if="finished" fill-height>
            <v-row justify="center" align="center" class="pt2" style="height: 100%;">
                <v-col xs="4">
                    <v-row justify="center" class="pt-2">
                        <h1 style="color: #A01441;">Geschafft!</h1>
                    </v-row>
                    <v-row justify="center">
                        <h4 style="white-space: pre-wrap; color: #6F6F6F; text-align: center;">{{endText}}</h4>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import TeamSelection from "../components/TeamSelection.vue";
const axios = require("axios");
import Cookies from "js-cookie";
import UserTask from '../components/UserTask.vue';

export default {
    name: "Rally",

    components: {
        TeamSelection,
        UserTask,
    },

    data: () => ({
        currentId: null,
        teamTasks: [],
        currentTeam: null,
        currentTask: null,
        teams: null,
        startText: null,
        endText: null,
        loading: false,
        errorWhileLoading: false,
        finished: false, 
        startDialog: false,
        snackbar: {
            open: false,
            text: "",
            type: "Info",
        },
    }),

    mounted() {
        this.currentId = this.$route.params.id;
        if (this.currentId === "") {
            this.$router.push("Home");
        }
        let teamId = Cookies.get("Team");
        this.loading = true;
        axios
            .get(this.$hostname + "rallies/" + this.currentId)
            .then((response) => {
                this.teams     = response.data.teams;
                this.startText = response.data.startText
                this.endText   = response.data.endText

                // User already has team id inside of cookies
                if (teamId && this.teams.map(team => team._id).includes(teamId)) {
                    axios.get(this.$hostname + "teams/" + teamId)
                        .then((response) => {
                            if (response.data === "FINISHED") {
                                this.finished = true
                                this.loading = false
                            } 
                            else {
                                this.currentTeam = response.data.team;
                                this.currentTask = response.data.currentTask;
                                this.loading = false;
                            }
                        })
                        .catch(() => {
                            this.loading = false
                            Cookies.remove("Team") //Remove cookie and let user rejoin 
                            this.handleError("Sorry, da hat was nicht geklappt! Versuche es noch einmal", 5000);
                        });
                } 
                else {
                    this.loading = false;
                    Cookies.remove("Team") //Remove cookie and let user rejoin
                }
            })
            .catch((error) => {
                if (error?.response?.status === 404)
                    this.handleError("Wo bist du denn unterwegs? Wir bringen dich nach Hause!", 5000);
                else if (error?.response?.status === 400)
                    this.handleError("Die Rally ist noch nicht aktiv! Wir bringen dich nach Hause!", 5000);
                else
                    this.handleError("Die Rally konnte nicht geladen werden. Wir bringen dich nach Hause!", 5000);
                Cookies.remove("Team") // User seems confused, delete Cookie to allow rejoining
            });
    },
    computed: {
        snackbarColor() {
            switch (this.snackbar.type) {
                case "Error":
                    return "red";
                case "Success":
                    return "green";
                case "Skipped":
                    return "";
                default:
                    return "";
            }
        },
    },
    watch: {
        finished (newVal) {
            if (newVal)
                this.toggleConfetti()
        }
    },
    methods: {
        continueToTasks(event) {
            this.currentTeam = event;
            Cookies.set("Team", this.currentTeam._id, { expires: 7 });
            this.getCurrentTask();
        },
        getCurrentTask() {
            this.loading = true
            axios
                .get(this.$hostname + `teams/${this.currentTeam._id}`)
                .then((response) => {
                    this.loading = false
                    if (response.data === "FINISHED")
                        this.finished = true
                    else {
                        if (this.currentTeam.currentTaskNumber === 0 && this.startText)
                            this.startDialog = true
                        this.currentTask = response.data.currentTask;
                    }
                }).catch(() => {
                    this.loading = false
                    this.openSnackbar("Es ist ein Fehler aufgetreten.", "Error");
                })
        },
        openSnackbar(text, type) {
            this.snackbar.open = true;
            this.snackbar.text = text;
            this.snackbar.type = type;
        },
        handleError(errorMessage, time) {
            this.openSnackbar(errorMessage, "Error");
            this.errorWhileLoading = true;
            setTimeout( () => this.$router.push({ name: "Home" }), time);
        },
        handleResult (result, skipped) {
            this.loading = false
            if (result.data === "FINISHED") {
                this.finished = true
            } else {
                if (!skipped)
                    this.openSnackbar("Antwort abgegeben", "Success")
                else 
                    this.openSnackbar("Frage übersprungen", "Skipped")
                this.currentTask = result.data
            }
        },
        handleErrorResult (err) {
            this.loading = false
            if (err.response.status === 400)
                    this.openSnackbar("Das war nicht der richtige QR-Code! Versuche es noch einmal.", "Error")
                else 
                    this.openSnackbar("Sorry, da hat was nicht geklappt! Versuche es noch einmal.", "Error")
        },
        sendAnswer (answer) {
            this.loading = true
            axios.post(`${this.$hostname}teams/${this.currentTeam._id}/answer`, {answer}).then((result) => {
                this.handleResult(result)
            }).catch((err) => {
                this.handleErrorResult(err)                
            })
        },
        toggleConfetti () {
            this.$confetti.start()
            // eslint-disable-next-line
            window.setTimeout(() => this.$confetti.stop(), 10000);
        },
        skipQuestion () {
            this.loading = true
            axios.post(`${this.$hostname}teams/${this.currentTeam._id}/skip`).then((result) => {
                this.handleResult(result, true)
            }).catch((err) => {
                this.handleErrorResult(err)
            })
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.errorWhileLoading) {
            next();
        } else {
            const answer = window.confirm(
                "Bist du dir sicher, dass du die Rally verlassen willst?"
            );
            if (answer) {
                next();
            } else {
                next(false);
            }
        }
    },
};
</script>
