<template>
  <div>
    <v-row justify="center">
        <v-col cols="8">
          <admin-nav-bar :current="'tasks'"></admin-nav-bar>
        </v-col>
    </v-row>
    
    <v-row justify="center">
      <v-col cols="10">
        <v-row justify="center">
            <h1>Tasks</h1>
        </v-row>    
        <v-row justify="center">
          <v-col cols="7">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn @click="$router.push({name: 'AdminTaskDetail', params: {id: 'create'}})" color="green">
                Neuen Task erstellen
              </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10">
            <v-data-table :headers="headers" :items="tasks" :search="search" @click:row="handleClick"  :items-per-page="-1" no-data-text="Keine Fragen verfügbar"
              :footer-props="{'items-per-page-options':[-1, 1, 5, 10, 25, 50, 100], 'items-per-page-text': 'Reihen pro Seite', 'items-per-page-all-text': 'Alle'}"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminNavBar from '../components/AdminNavBar.vue'
import axios from 'axios'

  export default {
    name: 'AdminTaskView',

    components: {
        AdminNavBar
    },
    
    data: () => ({
      headers: [
          {text: 'Name', value: 'name'},
          {text: 'Frage', value: 'question'},
          {text: 'Tags', value: 'tags'},
          {text: 'Fragen-Art', value: 'kind'},
          {text: 'Punkte', value: 'points'},
        ],
      tasks: [],
      loading: true,
      search: ''
    }),

    methods: {
      handleClick(row) {
        this.$router.push({name: 'AdminTaskDetail', params: {id: row._id}})
      }
    },
    
    mounted() {
      axios
          .get(this.$hostname + 'tasks', {
            headers: {
                Authorization: 'Bearer ' + this.$keycloak.token
                }
            })
          .then(response => {
            this.loading = false
            for(let task of response.data) {
              task.tags = task.tags.sort((a, b) => a.localeCompare(b))
            }
            this.tasks = response.data
          })
          .catch((err) => {
            this.loading = false
            this.$snackbar.openSnackbar("red", err)
          })
    }
  }
</script>