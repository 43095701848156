<template>
  <div>
    <v-row justify="center">
      <v-col cols="8">
      <admin-nav-bar :current="'templates'"></admin-nav-bar>
      </v-col>
    </v-row>     
    
    <v-row justify="center">
      <v-col cols="8">
        <v-row justify="center">
          <h1>Templates</h1>
        </v-row>
        <v-row justify="center">
          <v-btn  @click="$router.push({name: 'AdminTemplateDetail', params: {id: 'create'}})" color="green">
            Neues Template erstellen
          </v-btn>
        </v-row>
        
        <v-row>
          <v-col cols="6" offset="3">
            <admin-template-list>

            </admin-template-list>
          </v-col>
        </v-row>

        </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminNavBar from '../components/AdminNavBar.vue'
import AdminTemplateList from '../components/AdminTemplateList.vue'
import axios from 'axios'
  export default {
    name: 'AdminTemplateView',

    data: () => ({
      templates: []
    }),

    mounted() {
      axios
          .get(this.$hostname + "templates", {
        headers: {
         Authorization: 'Bearer ' + this.$keycloak.token
        }
       })
          .then(response => {
            this.loading = false
            this.templates = response.data
          })
          .catch(err => {
            this.loading = false
            this.$snackbar.openSnackbar("red", err)
          })
    },

    components: {
        AdminTemplateList,
        AdminNavBar,
    },
  }
</script>