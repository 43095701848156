<template>
    <v-container>
        <v-row align="center" justify="center" class="pb-4 pt-4">
            <v-col cols="8">
                <v-row align="center" justify="center">
                    <h2 style="text-align: center">
                        {{ welcomeText }}
                    </h2>
                </v-row>
            </v-col>
        </v-row>

        <v-row align="center" justify="center">
            <v-btn
                @click="toggleScanner()"
                v-if="!qrScanner && !scanComplete"
                dark
                rounded
                color="primary"
            >
                {{ qrScannerButtonText }}
            </v-btn>
        </v-row>
        <QRCodeScanner v-if="qrScanner"
            v-on:onDecode="onDecode"
            v-on:toggleScanner="toggleScanner"
        ></QRCodeScanner>
        <v-row v-if="scanComplete" justify="center">
            <v-progress-circular
                indeterminate
                color="#A01441"
            ></v-progress-circular>
        </v-row>
    </v-container>
</template>

<script>
import QRCodeScanner from "../components/QrCodeScanner.vue"

export default {
    name: "StartRally",
    components: {
        QRCodeScanner,
    },
    data: () => ({
        welcomeText: "Willkommen bei der Start-Tag Rally!",
        qrScannerButtonText: "Rally starten",
        qrScanner: false,
        scanComplete: false,
    }),

    methods: {
        onDecode(decodedString) {
            this.qrScanner = false;
            this.scanComplete = true;
            location.replace(decodedString)
        },

        toggleScanner() {
            this.qrScanner = !this.qrScanner;
        },
    },
};
</script>
