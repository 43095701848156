<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="390"
      >
      <v-card>
        <v-card-title class="text-h5">
          {{textSubject}}
        </v-card-title>
        <v-card-text>{{textBody}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="$emit('abortClicked')"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmClick"
          >
            {{confirmText}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "Dialog",
    props: ['dialog', 'subject', 'function'],

    data: () => ({
        //dialog: true    
    }),

    computed: {
      textSubject() {
        let subjectString = ''
        if(this.subject === 'task'){
          subjectString += 'Task'
        }
        else if(this.subject === 'template') {
          subjectString += 'Template'
        }
        else if (this.subject === 'rally'){
          subjectString += 'Rally'
        }
        else if (this.subject === 'change'){
          subjectString += 'Änderungen'
        }

        if(this.function === 'save') {
          subjectString += ' speichern?'
        }
        else if (this.function === 'delete'){
          subjectString += ' löschen?'
        }
        else if (this.function === 'start'){
          subjectString += ' starten?'
        }
        else if (this.function === 'stop'){
          subjectString += ' beenden?'
        }
        else if (this.function === 'return'){
          subjectString += ' verwerfen?'
        }
        return subjectString
      },
      textBody() {
        if(this.function === 'save'){
          return 'Sollen die Änderungen gespeichert werden?'
        }
        if(this.function === 'delete'){
          return 'Sollen die Daten gelöscht werden?'
        }
        if(this.function === 'start'){
          return 'Soll die Rally gestartet werden?'
        }
        if(this.function === 'stop'){
          return 'Soll die Rally beendet werden?'
        }
        if(this.function === 'return'){
          return 'Sollen die Änderungen verworfen werden?'
        }
        return ''
      },
      confirmText() {
        if(this.function === 'save'){
          return 'Speichern'
        } 
        else if (this.function === 'delete') {
          return 'Löschen'
        }
        else if (this.function === 'start') {
          return 'Lasset die Spiele beginnen'
        }
        else if (this.function === 'stop') {
          return 'Rally abschließen'
        }
        else if (this.function === 'return') {
          return 'Änderungen verwerfen'
        }
        return ''
      }
    },
    methods: {
      confirmClick() {
        if(this.function === 'save') {
          this.$emit('saveClicked');
        }
        else if(this.function === 'delete') {
          this.$emit('deleteClicked')
        }
        else if(this.function === 'start') {
          this.$emit('startClicked')
        }
        else if(this.function === 'stop') {
          this.$emit('stopClicked')
        }
        else if(this.function === 'return') {
          this.$emit('returnClicked')
        }
      }
    }
}
</script>

