<template>
  <div class="team-container">
      <h3>{{ teamSelectionHeadline }}</h3>
      <v-radio-group v-model="selectedTeam">
        <v-radio
          v-for="team in teams"
          :key="team.id"
          :label="'Team: ' + team.name"
          :value="team"
        ></v-radio>
      </v-radio-group>

      <v-btn
        color="#A01441"
        :disabled="isDisabled"
        class="white--text"
        @click="teamSelected()"
      >
        {{ selectButton }}
      </v-btn>
  </div>
</template>

<script>
export default {
  name: "TeamSelection",
  props: ["teams"],
  data: () => ({
    teamSelectionHeadline: "Wähle dein Team aus:",
    selectButton: "Dieses Team wählen",
    selectedTeam: null,
  }),

  computed: {
    isDisabled() {
      return this.selectedTeam == null;
    },
  },

  methods: {
    teamSelected() {
      this.$emit("teamSelected", this.selectedTeam);
    },
  },
};
</script>

<style>
.team-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
</style>
