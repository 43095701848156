<template>
  <div class="pt-3">
    <v-row v-if="!editable">
      <v-col cols="1" offset="3" class="vCenterContent">
        <v-btn color="#A01441" dark @click="$router.push({name: 'Templates'})" outlined>
          <v-icon dark left> mdi-arrow-left </v-icon>Zurück
        </v-btn>
      </v-col>
      <v-col cols="4">
        <h1 class="mt-2">Tasks für Template "{{name}}"</h1>
      </v-col>
      <v-col cols="1" :align-self="'center'">
        <v-icon @click="editable=true" v-if="!editable" color="primary">mdi-lead-pencil</v-icon>
        <v-spacer/>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="1" offset="2" class="vCenterContent">
        <v-btn color="#A01441" dark @click="returnDialog=true" outlined>
          <v-icon dark left> mdi-arrow-left </v-icon>Zurück
        </v-btn>
      </v-col>
      <v-col cols="3">
        <h1 class="mt-2 vCenterContent">Tasks für Template : </h1>
      </v-col>
      <v-col cols="3" :align-self="'center'" class="vCenterContent">
        <v-text-field label="Name" v-model="name" outlined :error-messages="nameErrors" @input="$v.name.$touch" hide-details="auto"></v-text-field>
      </v-col>
      <v-col cols="2">
        
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" offset="3" v-if="editable">
        <v-textarea label="Start Text" v-model="startText" outlined @input="$v.startText.$touch"></v-textarea>
      </v-col>

      <v-col cols="3" v-if="editable">
        <v-textarea label="End Text" v-model="endText" outlined @input="$v.endText.$touch"></v-textarea>
      </v-col>
        
    </v-row>

    <v-row justify="center" >
      <v-btn  v-if="editable"
              @click="saveDialog=true"
              color="green"
              outlined
              style="margin-right: 20px; margin-left: 10px"
              :disabled="$v.$invalid"
              >Speichern</v-btn
            >
      <v-btn  v-if="editable && !creation"
        @click="deleteDialog=true"
        color="primary"
        outlined
        style="margin-right: 20px; margin-left: 10px"
        >Löschen</v-btn
      >
    </v-row>
    <v-row v-if="!editable">
      <v-col cols="3" offset="3" class="centerContent">
        <p>Starttext: {{startText}}</p>
      </v-col>

      <v-col cols="3" class="centerContent">
        <p>Endtext: {{endText}}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" :offset="offset" class="centerContent">
        <h3>Ausgewählte Tasks</h3>
      </v-col>
      <v-col v-if="editable" cols="6" class="centerContent">
        <h3>Alternative Tasks</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" :offset="offset" class="fullHeight" style="height:inherit">
        <v-list
            three-line
            subheader
            class="fullHeight"
            >
          <draggable v-model="selectedTasksObj" group="selected" @start="drag=true; $v.selectedTasksObj.$touch()" @end="drag=false" @add="checkSelected" class="fullHeight" v-if="editable">
            <div v-for="task in selectedTasksObj" :key="task.id">
              <v-list-item class="grabable">
                <v-list-item-action v-if="editable" @click="moveTasks(task, 'selectedTasksObj')">
                  <v-checkbox @blur="$v.selectedTasksObj.$touch()" type="checkbox" 
                    :error-count="11" :error="!questionErrors.length < 1" :error-messages="questionErrors"
                    v-model="task.checked" ></v-checkbox><!-- @change="$v.selectedTasks.$touch()" --> <!-- :value="task._id"  -->
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{task.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{task.question}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider/>
            </div>
          </draggable>

          <div v-for="task in selectedTasksObj" :key="task.id" v-else>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{task.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{task.question}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider/>
            </div>
        </v-list>
      </v-col>

      <v-divider vertical v-if="editable"/>

      <v-col cols="6" :offset="offset" v-if="editable"  style="height:inherit">
        
        <v-list
            three-line
            subheader
            >
            <draggable v-model="unSelectedTasksObj" group="selected" @start="drag=true; $v.selectedTasksObj.$touch()" @end="drag=false" @add="uncheckUnselected" class="fullHeight">
              <div v-for="task in unSelectedTasksObj" :key="task.id">
                <v-list-item class="grabable">
                <v-list-item-action @click="moveTasks(task, 'unSelectedTasksObj')">
                  <v-checkbox @blur="$v.selectedTasks.$touch()" type="checkbox" 
                    :error-count="11" :error="!questionErrors.length < 1" :error-messages="questionErrors"
                    v-model="task.checked" ></v-checkbox><!-- @click="$v.selectedTasks.$touch" -->
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{task.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{task.question}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider/>
              </div>
            </draggable>
        </v-list>
      </v-col>
    </v-row>
    <Dialog v-bind:function="'delete'" v-bind:dialog="deleteDialog" v-bind:subject="'template'" v-on:deleteClicked="deleteTemplate()" v-on:abortClicked="deleteDialog=false"/>
    <Dialog v-bind:function="'save'" v-bind:dialog="saveDialog" v-bind:subject="'template'" v-on:saveClicked="save()" v-on:abortClicked="saveDialog=false"/>
    <Dialog v-bind:function="'return'" v-bind:dialog="returnDialog" v-bind:subject="'change'" v-on:returnClicked="undoEdit" v-on:abortClicked="returnDialog=false"/>
  </div>
  
</template>

<script>
import {required, minLength} from 'vuelidate/lib/validators'
import Dialog from '../components/Dialog.vue'
import draggable from 'vuedraggable'
const axios = require('axios')

  export default {
    props: [],
    name: 'AdminTemplateDetail',

    components: {
        Dialog,
        draggable
        
    },
    computed: {
      creation() {
        return this.$route.params.id === "create"
      },
      displayedTasks() {
        return this.allTasks.filter(task => this.editable ?
                                    true:
                                    this.selectedTasks.includes(task._id))
      },
      displayedSelectedTasks() {
        return this.allTasks.filter(task => this.selectedTasks.includes(task._id))
      },
      displayedNotSelectedTasks() {
        return this.allTasks.filter(task => !this.selectedTasks.includes(task._id))
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$error) return errors
        !this.$v.name.required && errors.push('Name wird benötigt')
        return errors
      },
      questionErrors () {
        const errors = []
        if (!this.$v.selectedTasksObj.$error) return errors
        if(!this.$v.selectedTasksObj.required || !this.$v.selectedTasksObj.minLength)
          errors.push('Bitte wählen Sie mehr fragen aus')
        return errors
      },
      offset() {
        return this.editable ? 0 : 3
      },
    },

    data: () => ({
      allTasks: [],
      deleteDialog: false,
      dialog: true,
      editable: false,
      endText: "",
      id: "create",
      name: "",
      saveDialog: false,
      selectedTasks: [],
      selectedTasksObj: [],
      startText: "",
      returnDialog: false,
      unSelectedTasksObj: [],
    }),

    validations: {
      name: {required},
      // Vuelidate evaluates empty array to more Than minLength 1, so required is added
      selectedTasksObj: {minLength: minLength(1), required},
      startText: {},
      endText: {}
    },

    mounted() {
      this.getInitialState()
    },

    methods: {
      undoEdit: function() {
        if(this.creation) {
          this.$router.push({name: 'Templates'})
        }
        this.getInitialState()
        this.returnDialog = false
        this.editable = false
      },
      getInitialState: function() {
        let urlId = this.$route.params.id
        if(urlId === "create"){
          this.editable = true
        }
        let getTask
        if(!this.creation){
          getTask = axios
            .get(this.$hostname + "templates/" + urlId, {
              headers: {
              Authorization: 'Bearer ' + this.$keycloak.token
              }
            })
          getTask.then(response => {
                  this.id = response.data._id,
                  this.startText = response.data.startText,
                  this.endText = response.data.endText,
                  this.name = response.data.name
                  this.selectedTasks = response.data.questions
                })
        }
        axios
            .get(this.$hostname + "tasks", {
            headers: {
                Authorization: 'Bearer ' + this.$keycloak.token
                }
            })
            .then(async response => {
              if(!this.creation) {
                await getTask
              }
              this.allTasks = response.data
              this.  selectedTasksObj = this.allTasks.filter(task => this.selectedTasks.includes(task._id)).map(task => {return {...task, checked: true}})
              this.unSelectedTasksObj = response.data.filter(task => !this.selectedTasks.includes(task._id)).map(task => {return {...task, checked: false}})
              this.selectedTasksObj.sort((a,b) => this.selectedTasks.indexOf(a._id) - this.selectedTasks.indexOf(b._id))
            })
            .catch(err => {
              this.$snackbar.openSnackbar("red", err)
            })
      },
      uncheckUnselected: function() {
        this.unSelectedTasksObj =  this.unSelectedTasksObj.map(task => {return {...task, checked: false}})
      },      
      checkSelected: function() {
        this.selectedTasksObj =  this.selectedTasksObj.map(task => {return {...task, checked: true}})
      },
      moveTasks: function(task, sourceString) {
        this.$v.selectedTasksObj.$touch()
        let source = this[sourceString]
        let sink = sourceString === 'selectedTasksObj' ? this['unSelectedTasksObj'] : this['selectedTasksObj']
        source.splice(source.indexOf(task), 1)
        sink.push(task)
      },
      save: function() {
        let operation = this.creation ? "post" : "put"
        let marshalledObj = {
            name: this.name,
            questions: this.selectedTasksObj.map(task => task._id),
            startText: this.startText,
            endText: this.endText
          }
        if (!this.creation) {
          marshalledObj.id = this.id
        }
        axios[operation](this.$hostname + "templates", marshalledObj, {
        headers: {
         Authorization: 'Bearer ' + this.$keycloak.token
        }
       })
          .then(() => {
            this.$router.push({name: 'Templates'})
          })
          .catch(error => {
            console.error(error)
          })
      },
      deleteTemplate: function() {
        axios
          .delete(this.$hostname + 'templates/' + this.id, {
        headers: {
         Authorization: 'Bearer ' + this.$keycloak.token
        }
       })
          .then(() => {
            this.$router.push({name: 'Templates'})
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
  }
</script>
<style scoped>
  .centerContent {
    display: flex;
    justify-content: center;
  }
  .vCenterContent {
    display: flex;
    align-items: center;
  }
  .grabable {
    cursor: grab;
  }
  .fullHeight {
    height:100%;
    min-height: 300px;
  }
</style>