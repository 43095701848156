<template>
  <v-app>
    <v-app-bar app>
      <img width="120px" src="./assets/logo_msg_3C.svg" class="pr-8" >
      <v-app-bar-title>Start-Tag Rally</v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view/>
      <v-snackbar v-model="snackbar.open" color="red" style="padding-bottom: 64px">
            {{ snackbar.text }}
        </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
document.title = "msg Starttag Rally"
export default {
    name: 'App',
    mounted () {
        this.snackbar = this.$snackbar
    },
    data: () => ({
        snackbar: {
            open: false,
            color: "red"
        }
    }),


};
</script>

<style>
.v-application {
    height: 100% !important;
}

.v-application--wrap {
    height: 100% !important;
    min-height: 100% !important;
}
</style>
