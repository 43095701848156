<template>
  <div>
    <v-row justify="center">
      <v-col cols="8">
      <admin-nav-bar v-bind:current="'rallies'"></admin-nav-bar>
      </v-col>
    </v-row>
    
    <v-row justify="center">
      <v-col cols="8">
        <v-row justify="center">
          <h1>Rallies</h1>
        </v-row>
        <v-row justify="center">
          <v-btn @click="$router.push({name: 'AdminRallyeCreate'})" color="green">Neue Rally erstellen</v-btn>
        </v-row>
        <ralley-list @test="(payload) => {analysisRally = payload;analysisDialog=true}"></ralley-list>
      </v-col>
    </v-row>
    <admin-rally-analysis  @close='analysisDialog=false' :dialog="analysisDialog" :rally="analysisRally"/>
  </div>
</template>

<script>
import AdminNavBar from '../components/AdminNavBar.vue'
import AdminRallyAnalysis from '../components/AdminRallyAnalysis.vue'
import RalleyList from '../components/RalleyList.vue'

  export default {
    name: 'AdminRally',

    components: {
        RalleyList,
        AdminNavBar,
        AdminRallyAnalysis,
    },

    data: () => ({
      analysisDialog: false,
      analysisRally: {},
      AccessToken: ""
    }),
  }
</script>
